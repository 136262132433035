import React from 'react';
import { AudioVideoView } from 'views/audio-video';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/expertises/audio-video.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';
import aiAssistantMessages from 'constants/translations/ai-assistant.json';

const AudioVideoPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{
                ...messages,
                ...caseStudiesMessages,
                ...aiAssistantMessages,
            }}
        >
            <AudioVideoView {...{ faqData }} />
        </Layout>
    );
};

AudioVideoPage.propTypes = {
    location: object.isRequired,
};

export default AudioVideoPage;

export const Head = () => <SEO tags={TAGS.AUDIO_VIDEO} />;

export const pageQuery = graphql`
    query AudioVideoPageQuery {
        allContentfulFaq(filter: { slug: { eq: "expertises/audio-video" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
